import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/index'
import './styles/lojasparticipantes.scss'
import { GatsbyImage } from "gatsby-plugin-image";
import LojasFiltradas from '../utils/lojas-participantes.json'
// import exemplo from '../images/produtos/exemplo.png'

const ProdutosDia = (data) => {
  return (
    <div className="col-lg-4 text-center text-white pt-5">
      <GatsbyImage image={data.img} alt="Imagem da oferta" />
    </div>
  );
}

const Footer = (data) => {
  return <>
    <div className="bg-imageFooterParticipantes">
      <div className="container h-100">
        <div className="row align-items-center">
          <div className="col-lg-4 col-8 mb-lg-0 mb-3 mt-3">
            <GatsbyImage image={data.clubdia} alt="icone do ClubDia" />
          </div>
          <div className="col-lg-5 col-7 text-center pt-3">
            <h2 className="text-yellow font-Gotham text-uppercase font-participantesh2">Estas Ofertas só<br/> são validas para<br/> clientes cadastrados.<br/><span className="text-justify text-white font-Gotham"> Baixe o app agora<br/>e cadastre-se já!</span></h2>
            <div className="row pt-2">
              <div className="col-6">
                <a href="https://play.google.com/store/apps/details?id=br.com.dia.customers&amp;referrer=utm_source%3Dsite%26utm_medium%3Dbutton%26utm_campaign%3Ddownloadapp" target="_blank" rel="noopener noreferrer">
                  <GatsbyImage image={data.googleplay} alt="icone do Google Play" className="mb-3" />
                </a>
              </div>
              <div className="col-6">
                <a href="https://click.google-analytics.com/redirect?tid=UA-26046188-5&amp;url=https%3A%2F%2Fitunes.apple.com%2Fus%2Fapp%2Fmeu-desconto-clubdia%2Fid1311617705%3Fmt%3D8&amp;aid=br.com.dia.customers&amp;idfa=%{idfa}&amp;cs=site&amp;cm=button&amp;cn=downloadapp" target="_blank" rel="noopener noreferrer">
                  <GatsbyImage image={data.appstore} alt="icone do APP Store" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-5 justify-content-center">
            <GatsbyImage image={data.hand} alt="imagem de uma mão segurando celular" />
          </div>
        </div>
      </div>
    </div>
    <div className="bg-white">
      <div className="container p-3">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 text-center">
            <h2 className="text-red mb-0 font-Gotham font-h2-red">Tá barato. Tá pertinho. Tá no DIA.</h2>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-redlojasParticipantes">
      <div className="container">
        <div className="row justify-content-center align-items-center pt-3 pb-3 text-white text-center">
          <div className="col-12">
            <h4 className="mb-2 font-Gotham text-uppercase"><span className="font-size-2 font-Gotham">C</span>onsulte as lojas participantes desta promoção.</h4>
            <p className="font-size-09 line-height-1">Ofertas válidas de 14 a 15/11/2020 para lojas DIA do estado de São Paulo. Consulte no site as cidades,
              endereços e horários de funcionamento de suas lojas e os telefones para sanar dúvidas. Os preços já estão
              com o desconto aplicado. Itens sujeitos à disponibilidade no estoque. Reservamo-nos o direito de corrigir erros
              de publicação.</p>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-blueBeba">
      <div className="container">
        <div className="row pt-3 pb-3">
          <div className="col-lg-7 text-white text-center">
            <p className="font-size-08">A VENDA E CONSUMO DE BEBIDAS ALCOÓLICAS SÃO PROIBIDOS PARA MENORES DE 18 ANOS CONFORMIDADE COM O ESTATUTO DA CRIANÇA E DO ADOLESCENTE (ART. 81, ING 11)..</p>
          </div>
          <div className="col-lg-5 align-items-center d-flex text-white justify-content-lg-left justify-content-center mt-lg-0 mt-2">
            <h3 className="font-Gotham mb-0 font-size-beba">BEBA COM MODERAÇÃO:</h3>
          </div>
        </div>
      </div>
    </div>
  </>;
}

const Lojas = (data) => {
  return (
    <div key={'index'} className="col-lg-6 col-12 mb-5">
      <div className="card card-lojas border-0">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="text-red font-size-2 mt-0 mb-1 text-uppercase">{data.loja.name}</h2>
            <p className="font-size-1" style={{ lineHeight: '24px' }}>
              {data.loja.address} - {data.loja.district}<br/> {data.loja.city} - {data.loja.uf}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const LojasParticipantes = ({ data }) => {
  // const idLojas = [63, 461, 474, 486, 504, 506, 522, 526, 533, 534, 555, 563, 1005, 1009, 1020, 1021, 1027, 1029, 1035, 1038, 1039, 1042, 1044, 1051, 1070, 1091, 1117, 1126, 1141, 1155, 9904]
  const breadC = {
    visible: true,
    color: 'white',
    items: [
      { path: '/', name: 'Home' },
      { path: '/lojas-participantes/', name: 'Lojas Participantes' }
    ]
  }

  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <title>Lojas Participantes - Dia Supermercados</title>
        <meta name="description" content="Lojas Participantes ClubDIA" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <GatsbyImage
        image={data.bannerDesktop.childImageSharp.gatsbyImageData}
        className="d-lg-block d-none" />
      <GatsbyImage
        image={data.bannerMobile.childImageSharp.gatsbyImageData}
        className="d-lg-none d-block" />
      <div className="bg-lojasparticipantes">
        <div className="container pt-5 pb-5">
          <div className="row justify-content-center">
            <ProdutosDia img={data.oferta1.childImageSharp.gatsbyImageData} />
            <ProdutosDia img={data.oferta2.childImageSharp.gatsbyImageData} />
            <ProdutosDia img={data.oferta3.childImageSharp.gatsbyImageData} />
            <ProdutosDia img={data.oferta4.childImageSharp.gatsbyImageData} />
            <ProdutosDia img={data.oferta5.childImageSharp.gatsbyImageData} />
            <ProdutosDia img={data.oferta6.childImageSharp.gatsbyImageData} />
            <ProdutosDia img={data.oferta7.childImageSharp.gatsbyImageData} />
            <ProdutosDia img={data.oferta8.childImageSharp.gatsbyImageData} />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-12 p-0">
          <h2 className="text-uppercase font-Yanone pt-3 pb-3">Lojas Participantes</h2>
        </div>
        <div className="row">
          {LojasFiltradas.map(loja => {
            return (
              <Lojas loja={loja} />
            )
          })}
        </div>
      </div>
      <Footer hand={data.hand.childImageSharp.gatsbyImageData} googleplay={data.googleplay.childImageSharp.gatsbyImageData} appstore={data.appstore.childImageSharp.gatsbyImageData} clubdia={data.logoclubdia.childImageSharp.gatsbyImageData} />
    </Layout>
  );
}

export default LojasParticipantes

export const query = graphql`{
  bannerDesktop: file(
    relativePath: {eq: "lojasparticipantes/banner-desktop-new.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerMobile: file(relativePath: {eq: "lojasparticipantes/banner-mobile.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  googleplay: file(relativePath: {eq: "lojasparticipantes/google-play.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  logoclubdia: file(relativePath: {eq: "lojasparticipantes/logo-clubdia.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  appstore: file(relativePath: {eq: "lojasparticipantes/app-store.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  hand: file(relativePath: {eq: "lojasparticipantes/hand.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  oferta1: file(relativePath: {eq: "lojasparticipantes/01.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  oferta2: file(relativePath: {eq: "lojasparticipantes/02.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  oferta3: file(relativePath: {eq: "lojasparticipantes/03.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  oferta4: file(relativePath: {eq: "lojasparticipantes/04.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  oferta5: file(relativePath: {eq: "lojasparticipantes/05.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  oferta6: file(relativePath: {eq: "lojasparticipantes/06.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  oferta7: file(relativePath: {eq: "lojasparticipantes/07.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  oferta8: file(relativePath: {eq: "lojasparticipantes/08.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  lojas: allCloudOfertasLoja {
    nodes {
      id
      slug
      address
      cep
      city
      district
      lat
      lng
      name
      reference
      status
      uf
      whatsapp
      mondayOpen
      mondayClose
      holidayOpen
      holidayClose
      sundayOpen
      sundayClose
      services {
        icon
        name
      }
      tariff {
        id
        name
      }
    }
  }
}
`
